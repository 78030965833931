import React from 'react'

// Icons
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'

// Modules
import ModuleHeader from './module-header';
import SmartLink from './../global/smart-link';

interface props {
  data: {
    title: string
    subtitle?: string
    cardTitle: string
    cardText?: string
    cardCta?: string
    cardImage: {
      url: string
      alt?: string
      title?: string
    }
    smartLink: {
      className?: string
      data: any
      children: any
      onClick?: any
    }
  }
}

const FeaturedProduct = ({data: {
  title,
  subtitle,
  cardTitle,
  cardText,
  cardCta,
  cardImage,
  smartLink
}}: props) => {
  return <section className="featured-product">
    <div className="outer-container">
      <div className="inner-container">
      <ModuleHeader
        classNames="featured-product__header"
        title={title}
        subtitle={subtitle}
      />
      <SmartLink className="featured-product__card" data={smartLink}>
        <div className="featured-product__card-content">
          <p className="featured-product__card-title">{cardTitle}</p>
          {cardText ? <p className="featured-product__card-text">{cardText}</p> : null}
          {cardCta ?
            <p className="featured-product__card-cta">{cardCta} <ArrowRightBlue/></p>
          : null}
        </div>
        <img className="featured-product__card-graphic"
          src={cardImage.url}
          alt={cardImage.alt ? cardImage.alt : ' '}
          title={cardImage.title ? cardImage.title : ''}
        />
      </SmartLink>
      </div>
    </div>
  </section>
}

export default FeaturedProduct
