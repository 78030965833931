import React from "react"
import Img from "gatsby-image/withIEPolyfill";
import SmartLink from "./../global/smart-link";
import {parseHTML} from "./../../helpers/parseHTML"

const ArticleBody = ({content}) => {
  let bodyHTML = [];

  for (let section = 0; section < content.length; section++) {
    const component = content[section];
    let bodyMarkup;
    if (component !== null) {
      let typename = component.__typename || component.id?.split("-")[0]; 
      switch (typename) {
        case 'DatoCmsArticleRichText':
          bodyMarkup = (
            <div key={section} className={`article-module ${component.isCalloutText ? 'article-module--callout' : ''}`}>
              <div className="outer-container">
                <div className="inner-container">
                  <div className="article-module__content rte">
                    {parseHTML(component.content)}
                  </div>
                </div>
              </div>
            </div> 
          )
          break;
          case 'DatoCmsEmbedModule':
            bodyMarkup = (
              <div key={section}  className="article-module-embed">
                <div className="outer-container">
                  <div className="inner-container">
                    <div className="article-module-embed__content rte">
                      {parseHTML(component.content)}
                    </div>
                    <SmartLink 
                      className="article-module-embed__transcript"
                      data={component.transcriptLink}
                    >
                      {component.transcriptText}
                    </SmartLink>
                  </div>
                </div>
              </div> 
            )
            break;
          case 'DatoCmsImageModule':
            bodyMarkup = (
              <div key={section}  className="article-module-image">
                <div className="outer-container">
                  <div className="inner-container">
                    {component.caption ?
                      <figure className="article-module-image__fig">
                        <Img
                          className=""
                          fluid={component.image.fluid}
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt={component.image.alt}
                          title={component.image.title}
                        />
                        <figcaption className="article-module-image__fig-caption">
                          {component.caption}
                          </figcaption>
                      </figure>
                      :
                      <Img
                        className=""
                        fluid={component.image.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt={component.image.alt}
                        title={component.image.title}
                      />
                    }
                  </div>
                </div>
              </div>
            )
            break;
            
        default:
          break;
      }
      bodyHTML.push(bodyMarkup)
    }
  }
  return bodyHTML;
}

export default ArticleBody;
