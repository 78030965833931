import React, { useState, useEffect, Fragment} from 'react';
import Img from "gatsby-image/withIEPolyfill";
import { parseHTML } from '../../helpers/parseHTML';
import { FluidObject } from 'gatsby-image';
import dayjs from 'dayjs';

interface props {
  title: string
  readTime: number | string
  date: Date
  backgroundImage: {
    fluid?: FluidObject
    alt: string
    url: string
  }
  author?: {
    name: string
    role?: string
    aboutTheAuthor?: string
  }
  category: Array<category>
  meta: {
    publishedAt: Date
  }
}

interface category {
  categoryTitle: string
}

export default function ArticleBanner({
  title,
  readTime,
  date,
  backgroundImage,
  author,
  meta,
  category
}: props) {
  const [maxed, setMaxed] = useState(false);
  let currentWidth = typeof window !== "undefined" ? window?.innerWidth : 0;
  let threshold  = 1920;
  
  const handleWindowResize = ()=> {
    if(typeof window !== "undefined"){
      currentWidth = window?.innerWidth;
    }              
    setMaxed(currentWidth >= threshold);
    
  }    
  handleWindowResize();
  useEffect(() => {              
    if(typeof window !== "undefined"){
      window.addEventListener('resize', handleWindowResize);
    }    
    return () => window.removeEventListener('resize', handleWindowResize)
  });
  return (
    <header className="article-banner" id="article-banner">
      {backgroundImage && backgroundImage.fluid ? 
        <Img
          className={`article-banner__background ${maxed ? 'maxed' : ''}`}
          fluid={backgroundImage.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={backgroundImage.alt}
        />
      :
        <div className="article-banner__background svg">
          <img 
            className="article-banner__background-svg"
            src={backgroundImage.url} 
            alt={backgroundImage.alt}
          />
        </div>
      }
      <div className="outer-container">
        <div className="inner-container">
          <div className="article-banner__content">
              {category ?
                <ul className="article-banner__content-category">
                  {category.map((categoryItem, index) => (
                    <li key={index}>
                      <p>
                        {categoryItem.categoryTitle}
                      </p>
                    </li>
                  ))}
                </ul>
              : null}
            <h1 className="article-banner__content-title">{parseHTML(title)}</h1>
            <p className="article-banner__content-datetime">
              {date ?
                <time dateTime={`${date}`}>{dayjs(date).format("MMM D, YYYY")}</time>
                : meta ?
                <time dateTime={`${meta.publishedAt}`}>{dayjs(meta.publishedAt).format("MMM D, YYYY")}</time>
              : null}
              {readTime ? <span> • {readTime} min read</span> : null}
            </p>
            {author ?
              <>
                <p className="article-banner__content-author">{author.name}</p>
                {author.role ? <p className="article-banner__content-role">{author.role}</p> : null}
              </>
            : null}
          </div>
        </div>
      </div>
    </header>
  )
}