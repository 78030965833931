import React, { useEffect, useState } from "react"

// Packages
import { graphql } from "gatsby"
import readingTime from 'reading-time'
import { parseHTML } from '../../helpers/parseHTML';

// Modules
import Layout from "../layouts/layout"
import SEO from "../layouts/seo"
import Footer from "../global/footer"
import Nav from '../global/nav';
import ArticleBody from '../body/article-body'
import Breadcrumbs from "../global/breadcrumbs"
import ArticleBanner from '../banners/article-banner'
import FeaturedProduct from '../modules/featured-product';
import PageBody from '../body/page-body'
import RelatedArticles from "../modules/related-articles";
import queryString from 'query-string';
import Mark from "mark.js";
import pluralize from "pluralize";
// Props
interface props {
  transitionStatus: string
  data?: any
}

const ArticleSummary = ({summaryHeading, summaryText}) => (
  summaryHeading && summaryText ? 
    <section className="article-body__summary">
      <div className="outer-container">
        <div className="inner-container">
          <div className="article-body__summary-content">
            <h2 className="article-body__summary-header">{summaryHeading}</h2>
            <div className="article-body__summary-rte rte">
              {parseHTML(summaryText)}
            </div>
          </div>
        </div>
      </div>
    </section>
  : null
)

const AboutTheAuthor = ({author: {aboutTheAuthor}}) => (
  aboutTheAuthor ? 
    <section className="article-body__author">
      <div className="outer-container">
        <div className="inner-container">
          <div className="article-body__author-content">
            <h2 className="article-body__author-title">About the Author</h2>
            <p className="article-body__author-text">
              {aboutTheAuthor}
            </p>
          </div>
        </div>
      </div>
    </section>
  : null
)
let markTimeout;
let marked = false;
export default function TemplateArticlePage({ transitionStatus, data }:props) {
  const { pageData, articleNodes } = data;
  const articles = articleNodes.edges.map(edge => edge.node);
  let readTime = 0
  const [userAgent, setUA] = useState<any>();
  let keyword;  
  
  useEffect(()=>{
    
    let { query } = queryString.parse(location.search);
    if(document.location.search.indexOf("?search-term=") > -1 && !query){
      query = decodeURI(document.location.search.split("=")[1]);
      keyword = query;
    }
    
    if(keyword && !marked){
      
      
      const markInstance = new Mark(document.querySelector("main"));
      clearTimeout(markTimeout);
      setTimeout(()=>{
        
      markInstance.unmark({
        
        done: () => {
          
          
            let modkey = pluralize.plural(keyword) === keyword ? pluralize.singular(keyword) : pluralize.plural(keyword);
            //markInstance.mark(modkey, {separateWordSearch:false}); 
            markInstance.mark(keyword, {separateWordSearch:false});
          
            let fullmatch = true;
            let mark = document.querySelector("main mark[data-markjs]"); //grab the first one                      
            if(mark?.childNodes.length){
                setTimeout(()=>{                                                   
                  mark?.scrollIntoView({block:"center", behavior:"smooth"})
                }, 1800);
               
            }else{
              fullmatch = false;
            }
            markInstance.mark(modkey, {separateWordSearch:true}); 
            markInstance.mark(keyword, {separateWordSearch:true});
            
            if(!fullmatch){
              console.log("no full match")
              
              mark = document.querySelector("mark[data-markjs]"); //grab the first one
              if(mark?.childNodes.length){
                if(!marked){
                  //let rect = mark?.getBoundingClientRect();
                  //console.log(mark);
                  
                  setTimeout(()=>{                
                    console.log(mark)
                    mark?.scrollIntoView({block:"center", behavior:"smooth"})
                  }, 1800);
                  marked = true;
                }
              }
            }
         
          
          
        }
      });
      }, 1000)
      marked = true;
      
    }
    let links = document.querySelectorAll("a");
    let basePath = document.location.origin+document.location.pathname;
    links.forEach((a)=>{
      let link = a.getAttribute("href");
      if(link && link?.indexOf("#") > -1){
        let split = link.split("#");
        let base = split[0];
        let anchor = split[1];
        if(base === basePath){ //only apply on the same page
          a.setAttribute("href", basePath+'#'+anchor);
          a.onclick = function(anchor, event){
              event.preventDefault();
              let target = document.querySelector(anchor);
              if(target){
                target.scrollIntoView({behavior:"smooth"});
              }                            
          }.bind(this, '#'+anchor)
        }        
      }      
    });
  });
  useEffect(() => {
    if(typeof window !== 'undefined'){
      console.log(navigator.userAgent)
      setUA(navigator.userAgent)
    }
  },[]);

  pageData.articleComponents.forEach(article => {
    article && article.content ?
      readTime += readingTime(article.content).minutes
      : readTime;
  })

  return (
    <>
      <div style="display:none" for-dato-crawler>
        {pageData.metaDescription}
      </div>
      <SEO
        title={pageData.pageTitle} 
        metaDescription={pageData.metaDescription} 
        ogDescription={pageData.ogDescription} 
        ogTitle={pageData.ogTitle} 
        ogImage={pageData.ogImage} 
        noindex={pageData.noindex}
      />
     <Nav isWebView={userAgent ? false : true}/>
      <Layout
        className={pageData.slug}
        transitionStatus={transitionStatus}
        isWebView={userAgent ? false : true}
      >
        {pageData.slug !== 'home' ? 
          <Breadcrumbs
            pageTitle={pageData.pageTitle}
            parents={pageData.parentHub}
            transitionStatus={transitionStatus}
          />
        : null}
        <ArticleBanner
          title={pageData.title}
          readTime={readTime.toFixed(0)}
          date={pageData.date}
          backgroundImage={pageData.backgroundImage}
          author={pageData.author}
          category={pageData.articleCategory}
          meta={pageData.meta}
        />
        <section className="article-body">
          <div className="article-body__content">
            <ArticleSummary
              summaryHeading={pageData.summaryHeading}
              summaryText={pageData.summaryText}
            />
            <ArticleBody
              content={pageData.articleComponents} 
            />
            {pageData.author ?
              <AboutTheAuthor author={pageData.author} />
            : null}
          </div>
          {pageData.articleFeaturedProduct ?
            <div className="article-body__footnote">
              <FeaturedProduct
                data={pageData.articleFeaturedProduct}
              />
            </div>
          : null}
        </section>
        {pageData.articleCategory || pageData.components ?
          <section className="page-body">
            <RelatedArticles
              articleId={pageData.id}
              category={pageData.articleCategory}
              articles={articles}
            />
            <PageBody components={pageData.components}/>
          </section>
        : null}
        <Footer isWebView={userAgent ? false : true}/>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    pageData: datoCmsArticlePage(slug: {eq: $slug}) {
      ...articlePageFragment
      __typename
      components{
        __typename
      }
    }
    articleNodes: allDatoCmsArticlePage(filter: {
      articleCategory: {
        elemMatch: {
          categoryTitle: {
            ne: null
          }
        }
       
      }
    }) {
      edges {
        node {
          ...articlePageFragment,
          __typename
          components{
            __typename
          }
        }
      }
    }
  }
`