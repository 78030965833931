import * as React from 'react';
import { MatchHeight } from 'js-match-height';
import { useEffect } from 'react';
import { isMobile } from './../../helpers/breakpoints';
import { useState } from 'react';
import dayjs from 'dayjs';

// Modules
import ModuleHeader from './module-header'
import HubArticleList from '../hub/hub-article-list'

// Props
interface props {
  articleId: string
  articles: any
  category: Array<Category>
}

interface Category {
  categoryTitle?: string
}

export default function RelatedArticles({articles, category, articleId}: props) {
  let isMounted = false;
  let listingCount = 0
  
  const categories = category?.reduce((acc, currentCat) => { 
    return [...acc, currentCat.categoryTitle]
  }, [])
  articles = articles.slice().sort((a, b) => { 
    const bdate = new Date(dayjs(b.date));
    const adate = new Date(dayjs(a.date));
    // @ts-ignore
    return bdate - adate
  });
  
  const sortedArticles = category ?
    articles.filter(art => {
      return art.id !== articleId ?
        art.articleCategory.filter((cat) => {
          if (listingCount < 3 && categories.indexOf(cat.categoryTitle) > -1) {
            listingCount += 1
            return true
          }
        }).length > 0
      : null;
    }) : articles;
    
  // const sortedArticles = filteredArticles.slice().sort((a, b) => { 
  //   const bdate = new Date(dayjs(b.meta.publishedAt));
  //   const adate = new Date(dayjs(a.meta.publishedAt));
  //   // @ts-ignore
  //   return bdate - adate
  // }).reverse()

  useEffect(() => {
    // match heights of articles
    if (!isMobile()) {
      // const matchHeight = new MatchHeight('.related-articles__list-item');
    }
    if (!isMounted) {
      isMounted = true;
    }
  });

  return (
    <>
      {sortedArticles && sortedArticles.length > 0 ?
        <div className="related-articles">
          <div className="outer-container">
            <div className="inner-container">
              <ModuleHeader
                classNames="related-articles__header"
                title="<strong>Related</strong> content"
              />
              <HubArticleList sortedArticles={sortedArticles} />
            </div>
          </div>
        </div>
      : null}
    </>
  )
}
